<template>
	<div class="Haastattelu">
		<div class="container-fluid">
			<div class="row">
				<div class="col"></div>
				<div class="col-sm-8">
					<img class="img-fluid fluid-grow" alt="" src="../../assets/tyohaastattelu.webp" style="width: 100%;">
				</div>
				<div class="col"></div>
			</div>
		</div>
		<div class="container-fluid" style="margin-top: 25px;">
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h2>Miten valmistautua haastatteluun</h2>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h3>Lähtökohdat ja kartoitus</h3>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<p>Tutustu yrityksen verkkosivuihin ja viestintään sosiaalisessa mediassa.<br>
					Minkälaisen työnantajakuvan yritys antaa?<br>
					Mistä asioista yritys viestii? Mitä yritys tuo eri viestintäkanavissa esille itsestään?</p>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h3>Selvitä</h3>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<p><ol><li>Yrityksen taustatiedot: liikevaihto, tilinpäätöstiedot, liiketoiminta-alueet, kilpailijat, markkina-asema, myynti ja myynnin kehitys, rekrytoinnit ym. siltä osin kuin mahdollista.</li><li>Yrityksen tuotteet, palvelut, toiminta-alue (paikallinen, alueellinen, kansallinen, kansainvälinen)</li><li>Arvot, visio, missio, strategia</li></ol></p>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h3>Työtehtävä</h3>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<p>Työn tarkempi sisältö, työnkuva ja sen vaatimukset.Mitkä ovat kolme keskeistä osaamisaluetta  työssä? Mitä ne tarkemmin sisältävät? Mitä niistä osaat? Mitä osaamista vielä tarvitsee kehittää?</p>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h3>Esittely</h3>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<p>Hissipuhe. Valmistaudu pitämään lyhyt hissipuhe itsestäsi:<br>
					Vastaa hissipuheessa kysymyksiin, kuten miksi juuri minä? Miksi juuri nyt? Miksi juuri tähän yritykseen? Ts. kiteytä mitä tuot ja annat yritykselle? (persoona, osaaminen, kokemus, taidot ja tiedot)</p>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h3>Haastattelukysymykset</h3>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<p>Valmistaudu ennalta ja käy läpi keskeisiä haastattelukysymyksiä. <br>
					Esimerkkejä kysymyksistä löydät muun muassa täältä: <a href="https://duunitori.fi/tyoelama/tyohaastattelukysymykset">https://duunitori.fi/tyoelama/tyohaastattelukysymykset</a></p>
					<p>Kysy apua esimerkiksi (entisiltä) työkavereilta, ystäviltä, läheisiltä jne. Miten he kuvailisivat sinua?<br>
					Laadi kysymyspatteristo, seuraa sitä haastattelussa - annat yritykseen perehtyneen kuvan ja yrityksestä kiinnostuneen kuvan haastattelijalle. <br>
					Varaudu keskusteluun: aloituspäivästä, palkkatoiveesta, henkilöstöeduista, etenemispolusta, uratavoitteista, koulutusmahdollisuuksista.  Missä näet itsesi esimerkiksi viiden tai kymmenen vuoden kuluttua?</p>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h3>Ennen haastattelua</h3>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<p>Kuvittele onnistunut haastattelutilanne mielessäsi.<br>
					Miten ja mitä reittiä pitkin saavut paikalle? Miten olet pukeutunut?<br>
					Miten kättelet haastattelijaa? Minkälainen olotila sinulla on?<br>
					Mitä sinun kannattaa ajatella haastattelusta ja haastattelijasta, että voit rentoutua ja rauhoittua haastattelussa?<br>
					Mikä auttaa sinua keskittymään kuuntelemiseen ja olemaan läsnä? </p>
					<p>Lopuksi: Nuku hyvät yöunet ennen haastattelupäivää...</p>
				</div>
				<div class="col"></div>
			</div>
		</div>
		<Social/>
	</div>
</template>

<script>
import Social from '../../components/Social.vue'

export default {
name: 'Haastattelu',
components: {
    Social
  }
}
</script>

<style type="text/css">
.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>